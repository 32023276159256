<template>
  <div>
    <!-- <Columns>
      <Column>
        <div class="is-flex is-align-items-center">
          <block-title customClass="my-0 mr-5" title="Milestones" />
          <button v-if="can('project-create')" class="button is-info is-small" @click.prevent="toggleMilestoneModal">Add new</button>
        </div>
      </Column>
    </Columns> -->

     <Columns v-if="loading">
      <Column>
        <Loading />
      </Column>
    </Columns>

    <Box v-if="!loading" customClass="is-shadowless is-transparent">

      <Columns customClass="">
        <Column>
          <transition-group name="list" tag="div">
            <div v-for="item in limitArray(milestones, 5)"  :key="item" class="list-item">
              <MilestoneItem :item="item" />
            </div>
          </transition-group>
        </Column>
      </Columns>
    </Box>

    <teleport to="body">
      <milestone-create v-if="create" :active="create" @closeCreateMilestoneModal="toggleMilestoneModal"  />
    </teleport>

  </div>
</template>

<script>

import { defineAsyncComponent, toRefs,reactive } from "vue";
import { getMilestones } from '@/use/useMilestones'
import {useUser} from "@/use/useUser";

export default {
  components: {
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    Box: defineAsyncComponent(() => import('@/components/ds/Box')),
    // BlockTitle: defineAsyncComponent(() => import('@/components/ds/BlockTitle')),
    Loading: defineAsyncComponent(() => import('@/components/general/Loading')),
    MilestoneItem: defineAsyncComponent(() => import('@/components/milestones/listing/item')),
    // MilestoneCreate: defineAsyncComponent(() => import('@/components/milestones/create')),
  },
  setup () {
    const { milestones, loading, error } = getMilestones()
    const { can } = useUser()

    const state = reactive({
      create: false,
    })

    const toggleMilestoneModal = () => {
       state.create = !state.create
    }

    const limitArray = (arr, length = 3) => {
      if (arr && arr.length) {
        if (length == -1) {
          return arr;
        }
        if (length > arr.length) {
          return arr;
        }

        return arr.slice(0, length);
      }

      return null;
    }

    return {
      ...toRefs(state),
      can,
      toggleMilestoneModal,
      milestones,
      loading,
      limitArray,
      error
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
