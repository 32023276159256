import { reactive, toRefs  } from "vue";
import api from '@/helpers/api'
import { env } from '@/configs'
import { useQuery, useResult } from '@vue/apollo-composable'
import { GET_ALL_MILESTONES } from '@/graphql/queries/milestonesQuery'

// const milestonesState = reactive({
//   data: null,
//   loading: true,
//   error: false
// })

// url: env.GITLAB_API_URL + '/groups/' +  gid + '/milestones',

// export default function useMilestones() {

//   function fetchIssues(params, gid) {
//     milestonesState.loading = true

//     api.get({
//       url: env.GITLAB_API_URL + '/groups/26/milestones',
//       data: params ? Object.assign({}, params) : ''
//     }).then(response => {

//         console.log(response)

//         if(response.status === 200) {

//           const _data = response.data
//           milestonesState.data = _data
//           milestonesState.loading = false
//         }
//       })
//       .catch(error => {
//         console.log(error);
//         milestonesState.error = true;
//       })
//       .finally(() => (
//         milestonesState.loading = false
//     ));
//   }


//   // let _params = { include_parent_milestones: true }
//   let _params = {}
//   let _gid = 41

//   function refetchIssues() {
//     milestonesState.data = fetchIssues(_params,_gid);
//   }

//   milestonesState.data = fetchIssues(_params,_gid);


//   return {
//     ...toRefs(milestonesState),
//     refetchIssues
//   }
// }

export const getMilestones = () => {

  const { result, loading, error, refetch } = useQuery(
      GET_ALL_MILESTONES,
      null,
      { fetchPolicy: 'cache-and-network' }
    )

  const milestones = useResult(result, null, data => data.group.milestones.edges )

  return {
    milestones,
    loading,
    error,
    refetch
  }

}

export const createMilestone = (payload, gid) => {
  return api.post({
    url: env.GITLAB_API_URL + '/groups/' + gid + '/milestones',
    data: Object.assign({}, payload)
  }).then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}
