import gql from 'graphql-tag'


export const GET_ALL_MILESTONES = gql`
query {
  group(fullPath: "dev") {
    milestones {
      edges {
        node {
          dueDate
          description
          title
          state
          startDate
          dueDate
        }
      }
    }
  }
}
`
